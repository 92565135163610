import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import HeroBackground from "../images/home-hero.jpg";
import StartNowButton2 from "../components/StartNowButton2"
import StartNowButton from "../components/StartNowButton"

import mixins from "../lib/mixins";
import MaxWidthWrapper from "./MaxWidthWrapper";
import { Header4, Lead } from './Typography';

import { Flour } from '../lib/colors';
import { sizes } from '../lib/layout';
import  tracking from '../lib/tracking';
import { BR1, BR2, BR3 } from '../lib/commonStyle';

const Wrapper = styled(MaxWidthWrapper)`
	margin-top: 40px;

	@media (min-width: 1920px){
		max-width: 1640px
	}	
`;

const HeroContentContainer = styled.div`
	// background-color: var(--secondary-color);
	color: ${Flour};
	padding: 36px 48px;
	position: relative;
	border-radius: 5px;
	overflow: hidden;
	
	.bg{
		${mixins.background};
	}

	.content{
		max-width: 450px;
	}

	.links-wrapper{
		display: flex;
	}

	@media (max-width: ${sizes.modifiedTablet}) and (min-width: ${sizes.mobileL}) {
		text-align: center;
		margin: 3rem auto 4rem;
		// max-width: 21rem;
	}

	@media (max-width: ${sizes.mobileL}) and (min-width: ${sizes.mobileS}) {
		text-align: center;
		margin: 3rem auto 4rem;
		// max-width: 21rem;
	}

	@media (max-width: ${sizes.laptop}){
		background-color: var(--secondary-color);

		.bg{
			display: none;
		}
	}

	@media (max-width: ${sizes.tablet}){
		.content{
			margin: 0 auto;
		}

		.links-wrapper{
			${mixins.flexColumnCenter};
		}
	}
`;

const HeroHeader = styled(Header4)`
	font-weight: 400;

	@media (max-width: ${sizes.modifiedTablet}) {
		font-size: 1.5rem;
	}
`;

const HeroLead = styled(Lead)`
	font-weight: 400;
	font-size: 1.6;
	
	@media (max-width: ${sizes.modifiedTablet}) {
		font-size: 1rem;
	}
`;

const HeroButton = styled.button`
	${mixins.button};
	width: 210px;
	max-width: 100%;
	min-height: 55px;

	&.subscribe{
		background-color: var(--light-green);
		color: #fff;
		border-radius: 5px;
	}

	&.see-more{
		${mixins.outlinedButton};
		color: #fff;
		border: 2px solid #fff;
		background-color: transparent;

		&:hover{
			background-color: #fff;
			color: var(--secondary-color);
		}
	}
`;

const HeroButtonLink = styled.a`
	text-decoration: none;
	cursor: pointer;

	&:first-child{	
		margin-right: 10px;
	}

	&:hover {
		text-decoration: none;
	}
`;

const ModalHeaderExtended = styled(Modal.Header)`
  border: none !important;
`;

const IframeContainer = styled.div`
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;

	iframe {
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
	}
`;

const Hero = () => {
	const [ show, toggleModalShow ] = useState(false);

	return (
		<Wrapper as="section" maxWidth={1300}>
			<HeroContentContainer>
				<img src={HeroBackground} className="bg" />

				<div className="content">
					<HeroHeader color={Flour}>Oracle Cloud experts </HeroHeader>
					<BR1 />
					<HeroLead color={Flour}>
						Hundreds of oracle cloud customers have selected mivors to be thier cloud transfomation partner.
					</HeroLead>
					<BR2 />

					<div className="links-wrapper">
						  <HeroButtonLink> 
							{/* <HeroButton className="subscribe">Start Now</HeroButton> */}
							<StartNowButton2 className="subscribe" />
						</HeroButtonLink>
						<BR3 />
						<HeroButtonLink>
							<HeroButton tertiary onClick={() => {toggleModalShow(true);
							  tracking.eventTrack('interaction', 'click', 'see_our_story');
							}} className="see-more">
								See Our Story
							</HeroButton>
						</HeroButtonLink>
					</div>
				</div>

				<div className="img-wrapper">
				</div>

				<Modal centered size="lg" show={show} onHide={() => toggleModalShow(false)}>
					<ModalHeaderExtended closeButton />
					<Modal.Body>
						<IframeContainer>
							<iframe 
								width="560" 
								height="315" 
								src="https://www.youtube.com/embed/3eNVbJeUzIE" 
								title="YouTube video player" 
								frameborder="0" 
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
								allowfullscreen 
							/>
						</IframeContainer> 
					</Modal.Body>
				</Modal>
			</HeroContentContainer>
		</Wrapper>
	);
};

Hero.propTypes = {};

export default Hero;

import React from "react"
import styled from "styled-components"
import MaxWidthWrapper from "./MaxWidthWrapper";
import { Header5 } from "./Typography"

import { Ghost, Fade } from "../lib/colors"
import { sizes } from "../lib/layout"

import CitFeiLogo from "../images/citfeilogo2.png"
import oraclecustomersuccess from "../images/Oracle_logo.png"
import entrepreneuralarabiya from "../images/logo_entrepreneuralarabiya.png"
import DigitalTrends from "../images/logo_digtrends.png"
import GreenPolygon from "../images/green-polygon.svg";

const ASIContainer = styled(MaxWidthWrapper)`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;

  @media (max-width: ${sizes.modifiedTablet}) and (min-width: ${sizes.mobileL}) {
    margin: 0;
  }

  @media (max-width: ${sizes.mobileL}) and (min-width: ${sizes.mobileS}) {
    margin: 0;
  }
`

const Header = styled(Header5)`
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .polygon{
    width: 30px;
    margin: 0 10px;
  }

  @media (max-width: ${sizes.modifiedTablet}) and (min-width: ${sizes.mobileL}) {
    font-size: 1.5rem;
  }

  @media (max-width: ${sizes.mobileL}) and (min-width: ${sizes.mobileS}) {
    font-size: 1.5rem;
  }
`

const PartnerRow = styled.div`
  margin-top: 2.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: ${sizes.modifiedTablet}) and (min-width: ${sizes.mobileL}) {
    justify-content: space-around;
    margin-top: 2.4rem;
  }

  @media (max-width: ${sizes.mobileL}) and (min-width: ${sizes.mobileS}) {
    margin-top: 0;
    flex-direction: column;
    align-items: center;
  }
`

const Link = styled.a`  
  &:last-child {
    img {
      height: 2.5rem;
    }
  }
`

const PartnerLogo = styled.img`
  height: auto;
  max-height: 3.75rem;
  max-width: 300px;
  margin: 0 50px;

  &.cit{
    max-height: auto;
    transform: scale(1.5);
    margin-left: 60px;
  }

  @media (max-width: ${sizes.modifiedTablet}) and (min-width: ${sizes.mobileL}) {
    margin: 0;
  }

  @media (max-width: ${sizes.mobileL}) and (min-width: ${sizes.mobileS}) {
    margin-top: 2rem;
  }
`

const AsSeenIn = () => {
  return (
    <ASIContainer as="section" maxWidth={1300}>
      <Header>
        <img src={GreenPolygon} className="polygon" />
          As Seen In
        <img src={GreenPolygon} className="polygon" />
      </Header>
      <PartnerRow>
        <Link
          href="https://entrepreneuralarabiya.com/2017/05/29/11183/%D9%83%D9%8A%D9%81-%D8%AA%D8%B3%D8%A7%D9%8A%D8%B1-mivors-%D8%A7%D9%84%D8%AA%D8%AD%D9%88%D9%84-%D8%A7%D9%84%D8%B1%D9%82%D9%85%D9%8A-%D9%84%D9%84%D8%B4%D8%B1%D9%83%D8%A7%D8%AA-%D8%A8%D8%AF%D8%B9%D9%85/"
          target="_blank"
        >
          <PartnerLogo src={entrepreneuralarabiya} />
        </Link>
        <Link
          href="https://www.oracle.com/sa/customers/alsafwa-1-adv-customer-sv/"
          target="_blank"
        >
          <PartnerLogo src={oraclecustomersuccess} />
        </Link>
        <Link
          href="http://cit-fei.org/ar-eg/default/member-details/member-details/854"
          target="_blank"
        >
          <PartnerLogo src={CitFeiLogo} className="cit" />
        </Link>
        {/* <Link
          href="https://www.digitaltrends.com/home/best-cannabis-cooking-gadgets/"
          target="_blank"
        >
          <PartnerLogo src={DigitalTrends} />
        </Link> */}
      </PartnerRow>
    </ASIContainer>
  )
}

export default AsSeenIn

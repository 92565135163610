import React from "react"
import styled from "styled-components"

import { Body, Header5 } from "./Typography"

import { sizes } from "../lib/layout"

import OracleTester from "../images/Oracletester2.png"
import OrangeHex from "../images/hex_orange.svg"

const ProductRowContainer = styled.section`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  // height: 27rem;
  padding: 50px 0;
  background-color: #fff;
  margin-top: 30px;

  @media (max-width: ${sizes.mobileL}) and (min-width: ${sizes.mobileS}) {
    flex-direction: column;
  }
`

const ProductTextSection = styled.div`
  width: 29%;

  @media (max-width: ${sizes.modifiedTablet}) and (min-width: ${sizes.mobileL}) {
    width: 40%;
  }

  @media (max-width: ${sizes.mobileL}) and (min-width: ${sizes.mobileS}) {
    order: 2;
    width: 90%;
    text-align: center;
  }
`

const ProductTitle = styled(Header5)`
  margin-bottom: 2rem;
  position: relative;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 1.5;
  
  span{
    position: relative;
    z-index: 2;
  }

  &:before {
    content: url(${OrangeHex});
    position: absolute;
    top: -12px;
    left: -23px;
  }

  @media (max-width: ${sizes.mobileL}) and (min-width: ${sizes.mobileS}) {
    margin-bottom: 2rem;
    &:before {
      content: unset;
    }
  }
`

const ProductImage = styled.img`
  max-width: 50%;

  @media (max-width: ${sizes.modifiedTablet}) and (min-width: ${sizes.mobileL}) {
  }

  @media (max-width: ${sizes.mobileL}) and (min-width: ${sizes.mobileS}) {
    margin-top: 80px;
  }
`

// What do I call this thing?
const ProductRowA = () => {
  return (
    <ProductRowContainer>
      <ProductTextSection>
        <ProductTitle><span>Oracle Experts for <strong>delivering measurable values for your cloud transformation</strong></span></ProductTitle>
      </ProductTextSection>

      <ProductImage src={OracleTester} />
    </ProductRowContainer>
  )
}

export default ProductRowA

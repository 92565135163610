import React from "react"
import styled from "styled-components"
import MaxWidthWrapper from "./MaxWidthWrapper";
import { FEATURES } from "../lib/constants";
import mixins from "../lib/mixins";
import { sizes } from "../lib/layout";

const Wrapper = styled(MaxWidthWrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
`;

const Feature = styled.div`
  width: calc(100% / 3);
  ${mixins.flexColumnCenter};
  text-align: center;
  padding: 10px;

  .img-wrapper{
    margin: 0 0 18px;
  }

  .title{
    font-size: 1.5rem;
  }

  .description{
    font-size: 1rem;
    line-height: 1.5;
  }

  @media (max-width: ${sizes.laptop}){
    width: 50%;
  }

  @media (max-width: ${sizes.tablet}){
    width: 100%;
  }
`;

function AppFeatures(){
  return (
    <Wrapper maxWidth={1300}>
      {
        FEATURES.map((feature, index) => (
          <Feature key={index}>
            <div className="img-wrapper">
              <img src={feature.img} alt="app feature" />
            </div>

            <div className="title">
              {feature.title}
            </div>

            <p className="description">
              {feature.description}
            </p>
          </Feature>
        ))
      }
    </Wrapper>
  )
}

export default AppFeatures
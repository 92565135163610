import React from "react"
import styled from "styled-components"

import { Header5 } from "./Typography"

import { sizes } from "../lib/layout"
import { CLOUD_SERVICES } from "../lib/constants";

import MaxWidthWrapper from "./MaxWidthWrapper"
import mixins from "../lib/mixins";

const Title = styled(Header5)`
  margin-bottom: 2rem;
  position: relative;
  max-width: 530px;
  margin: auto;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text-primary-color);

  @media (max-width: ${sizes.mobileLg}) and (min-width: ${sizes.mobileS}) {
      padding: 0px 12px;
  }

  @media (max-width: ${sizes.mobileL}) and (min-width: ${sizes.mobileS}) {
    margin-bottom: 2rem;
    &:before {
      content: unset;
    }
  }
`

const SubTitle = styled.p`
    max-width: 570px;
    margin: auto;
    font-size: 16px;
    width: 100%;
    color: var(--text-secondary-color);
    margin-top: 16px;
    font-size: 1.5rem;

    @media (max-width: ${sizes.mobileLg}) and (min-width: ${sizes.mobileS}) {
        font-size: 16px;
        width: 90%;
        margin-bottom: 40px;
      } 
`

const ValuePropsContainer = styled(MaxWidthWrapper)`
  margin-top: 50px;
  text-align: center;
  ${mixins.flexColumnCenter};

  .cloud-services{
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 16px;
    justify-items: center;
    width: 100%;
    background-color: #fff;
    padding: 24px;

    @media (max-width: ${sizes.mobileM}){
      grid-template-columns: 1fr;
    }
  }
`

const Service = styled.div`
  .service-title p{
    color: var(--text-secondary-color);
    margin-bottom: 0;

    span{
      font-weight: 600;
      display: block;
    }
  }

  .service-img{
    margin-bottom: 16px;
  }
`;

const PortableLivePotencyTesting = React.memo(() => {
  return (
    <ValuePropsContainer as="section" maxWidth={1300}>
        <Title>Start Your Cloud Transformation for ERP, HCM, CX and BI/EPM</Title>
        <SubTitle>Select from pre-build business flows for your industry and go live in just a couple of months.</SubTitle>

        <div className="cloud-services">
          {
            CLOUD_SERVICES.map((service, index) => (
              <Service key={index}>
                <img src={service.img} alt="service" className="service-img" />
                <div className="service-title">{service.title}</div>
              </Service>
            ))
          }
        </div>
    </ValuePropsContainer>
  )
})

export default PortableLivePotencyTesting

import styled from "styled-components"
import { tGreen, Ink, Flour } from "../lib/colors"
import { openPopupWidget } from "react-calendly";
import tracking from "../lib/tracking"
import React from "react"
import mixins from "../lib/mixins";


const pageSettings = {
  backgroundColor: 'ffffff',
  hideEventTypeDetails: false,
  hideLandingPageDetails: false,
  primaryColor: '00a2ff',
  textColor: '4d5055'
};

const url = 'https://calendly.com/mivors/request-for-free-trial';

const prefill = {
  // email: 'test@test.com',
  // firstName: 'Jon',
  // lastName: 'Snow',
  // name: 'Jon Snow',
  // guests: [
  //   'janedoe@example.com',
  //   'johndoe@example.com'
  // ],
  // customAnswers: {
  //   a1: 'a1',
  //   a2: 'a2',
  //   a3: 'a3',
  //   a4: 'a4',
  //   a5: 'a5',
  //   a6: 'a6',
  //   a7: 'a7',
  //   a8: 'a8',
  //   a9: 'a9',
  //   a10: 'a10'
  // },
  date: new Date(Date.now() + 86400000)
};

const utm={
  utmCampaign: 'Spring Sale 2019',
  utmContent: 'Shoe and Shirts',
  utmMedium: 'Ad',
  utmSource: 'Facebook',
  utmTerm: 'Spring'
};

const Button = styled.button`
${mixins.button};
width: 210px;
max-width: 100%;
min-height: 55px;

&.subscribe{
  background-color: var(--light-green);
  color: #fff;
  border-radius: 5px;
}

&.see-more{
  ${mixins.outlinedButton};
  color: #fff;
  border: 2px solid #fff;
  background-color: transparent;

  &:hover{
    background-color: #fff;
    color: var(--secondary-color);
  }
}
`
const HeroButtonLink = styled.a`
	text-decoration: none;
	cursor: pointer;

	&:first-child{
		margin-right: 10px;
	}

	&:hover {
		text-decoration: none;
	}
`;

// const StartNowButton = ({ url, prefill, pageSettings, utm }) => {
  const StartNowButton2 = ({ className }) => {
  const onClick = () => { openPopupWidget({ url, prefill, pageSettings, utm });
    tracking.eventTrack('interaction', 'click', 'start_now');
    }

  return <Button className={className} onClick={onClick}>Start Now</Button>;
};

export default StartNowButton2

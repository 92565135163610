import React from "react"
import styled from "styled-components"
import MaxWidthWrapper from "./MaxWidthWrapper";
import OracleTech from "../images/oracle-tech.jpg";
import mixins from "../lib/mixins";
import StartNowButton from "../components/StartNowButton"
 


const Wrapper = styled(MaxWidthWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 60px auto 60px;
  margin-top: 50px;

  .bg{
    position: relative;
    grid-column: 1 / -1;
    grid-row: 2 / 3;

    img{
      ${mixins.background};
      z-index: 1;
    }
  }
`;


const Content = styled.div`
  background-color: var(--primary-color);
  grid-column: 2 / 3;
  grid-row: 1 / -1;
  padding: 60px 30px 30px; 
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  max-width: 450px;
  margin-left: auto;
  color: #fff;

  .title{
    font-size: 1.7rem;
    line-height: 1.4;
    margin: 0 0 20px;
  }

  .description{
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 24px;
  }

  a{
    ${mixins.button};
    ${mixins.outlinedButton};

    &:hover{
      text-decoration: none;
    }
  }
`;





function BuyCTA(){
  return (
    <Wrapper as="section" maxWidth={1200}>
      <div className="bg">
        <img src={OracleTech} />
      </div>

      <Content>
        <h4 className="title">
          Everything you need to <strong>transform your business</strong> flow on oracle cloud <strong>applications</strong>
        </h4>

        <p className="description">
          One Stop Shopping Experience for all things Oracle Cloud ERP,CX, BI, EPM & HCM. We offer a full range of services, from requirements gathering, through solution design and implementation to ongoing training and support, we do it all!.
        </p>

        {/* <a href="https://gamma.icloud-ready.com/marketplace" target="_blank"> */}
        {/* <CustomButton url="https://calendly.com/icloudready/request-discovery" prefill={prefill} pageSettings={pageSettings} utm={utm}/> */}
       <StartNowButton/>
          {/* Start Now */}
        {/* </a> */}
      </Content>
    </Wrapper>
  )
}

export default BuyCTA

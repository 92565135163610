import React from "react"
import styled from "styled-components"
import MaxWidthWrapper from "./MaxWidthWrapper";
import AppStorePicture from "../images/app-store.svg"
import GoogleStorePicture from "../images/google-play.svg"
import LargePhoneImage from "../images/large-phone.png";
import SmallPhoneImage from "../images/small-phone.png";
import LargePolygon from "../images/large-polygon.png";
import Screen1 from "../images/home-mobile-screen1.jpeg";
import Screen2 from "../images/home-mobile-screen2.jpeg";
import mixins from "../lib/mixins";

const Section = styled.section`
  background-color: #fff;
  padding: 50px 0 50px;
`;

const Wrapper = styled(MaxWidthWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  align-items: center;
`;

const Images = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  .phone{
    z-index: 2;

    img{
      max-height: 100%;
      border-radius: 15px;
      border: 10px solid #474850;
    }
  }

  .large-phone{
    width: 200px;
    margin: 0 20px 0 0;
  }

  .small-phone{
    width: 180px;
  }

  .large-polygon{
    position: absolute;
    width: 300px;
    left: 0;
    top: 0;
  }
`;

const Content = styled.div`
  justify-self: flex-end;

  h3{
    font-size: 2rem;
    margin: 0 0 16px;
  }

  p{
    font-size: 1.4rem;
    margin: 0 0 16px;
  }

  .links-wrapper a{
    margin: 0 16px 0 0;
  }
`;

const AppCTA = () => {
  return (
    <Section>
      <Wrapper maxWidth={1200}>
        <Images>
          <div className="phone large-phone">
            {/* <img src={LargePhoneImage} /> */}
            <img src={Screen2} className="screen" />
          </div>
          
          <div className="phone small-phone">
            {/* <img src={SmallPhoneImage} /> */}
            <img src={Screen1} className="screen" />
          </div>

          <img src={LargePolygon} className="large-polygon" />
        </Images>

        <Content>
          <h3>Get the mivors app</h3>
          <p>Start your joureny from anywhere.</p>

          <div className="links-wrapper">
            <a href="https://itunes.apple.com/us/app/mivors/?mt=8" target="_blank">
              <img src={AppStorePicture} />
            </a>

            <a href="https://play.google.com/store/apps/details?id=me.mivors.tc2" target="_blank">
              <img src={GoogleStorePicture} />
            </a>
          </div>
        </Content>
      </Wrapper>
    </Section>
  )
}

export default AppCTA

import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import ValuePropsRow from "../components/ValuePropsRow"
import ProductRowA from "../components/ProductRowA"
import ProductRowB from "../components/ProductRowB"
import BuyCTA from "../components/BuyCTA"
import AllOracleProf from "../components/AllOracleProf"
import AppCTA from "../components/AppCTA"
import AsSeenIn from "../components/AsSeenIn"
import PortableLivePotencyTesting from "../components/PortableLivePotencyTesting"

import { sizes } from "../lib/layout"


const PaddingContainer = styled.div`
  padding: 0 80px;

  @media (max-width: ${sizes.modifiedTablet}) {
    padding: 0;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO
      title="“mivors, oracle cloud experts"
      keywords={[
        `oracle cloud`,
        `mucs`,
        `fuion application`,
        `cx`,
        `mivors reviews`,
        `erp`,
        `hcm`,
        `managed services`,
        `oracle support`,
        `cloud`,
      ]}
      scripts={[
        '<script> </script>'
      ]}
    />



    <Hero />
    <PortableLivePotencyTesting/>
    <ProductRowA />
    <ProductRowB />
    <ValuePropsRow />
    <BuyCTA />
    <AllOracleProf />
    {/* <AppCTA /> */}
    <AsSeenIn />
  </Layout>
)

export default IndexPage

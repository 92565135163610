import React from "react"
//import { Link } from "gatsby"
import Link from './Link'
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons"
import ProductCarousel from "./ProductCarousel"
import MaxWidthWrapper from "./MaxWidthWrapper";
import { APPS } from "../lib/constants";
import productCarouselImages from "../lib/productCarouselImages"

import PC1 from "../images/home-slider1.jpeg"
import PC2 from "../images/home-slider2.jpeg"
import PC3 from "../images/home-slider3.jpeg"
import PC4 from "../images/home-slider4.jpeg"

import { sizes } from "../lib/layout"

const Wrapper = styled(MaxWidthWrapper)`
  margin-top: 80px;
  margin-bottom: 50px;
`;

const Container = styled.div`
  background-color: var(--primary-color);
  color: #fff;
  padding: 36px;

  .title{
    font-size: 1.7rem;
    font-weight: 500;
    margin: 0 0 24px;
  }

  .grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
`;

const AppContent = styled.div`
  margin: 0 0 24px;

  .name{
    margin: 0 0 8px;
    color: #12A79E;
    font-size: 1rem;
  }

  .description{
    margin: 0 0 8px;
  }
`;

const LearnMore = styled(Link)`
  display: flex;
  align-items: center;
  line-height: 0;
  color: #3AB44A;
  cursor: pointer;

  span{
    margin: 0 10px 0 0;
  }

  &:hover{
    color: #3AB44A;
  }
`;

const Carousel = styled.div`
  transform: translateY(150px);

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ProfessionalImages = styled.img`
  border-radius: 8px;
`

const AllOracleProf = () => {
  return (
    <Wrapper as="section" maxWidth={1200}>
      <Container>
        <h3 className="title">For every business flow</h3>

        <div className="grid">
          <div className="apps">
            {
              APPS.map(({name, description, href}, index) => (
                <AppContent key={index}>
                  <h4 className="name">{name}</h4>
                  <p className="description">{description}</p>
                  <LearnMore to={href} data-track-location="allOracleServices">
                    <span>
                      Learn More
                    </span>
                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </LearnMore>
                </AppContent>
              ))
            }
          </div>

          <Carousel>
            <ProductCarousel
              autoPlay={true}
              interval={3000}
              infiniteLoop={true}
              arrows={true}
            >
              {[PC1, PC2, PC3, PC4].map(img => {
                return <ProfessionalImages src={img} />
              })}
            </ProductCarousel>
          </Carousel>
        </div>
      </Container>
    </Wrapper>
  )
}

export default AllOracleProf
